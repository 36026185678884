<template>
  <div>
    <goodsList :onsell="onsell" ></goodsList>
  </div>
</template>

<script>
import goodsList from './list.vue'
export default {
  components: {
    goodsList
  },
  data () {
    return {
      onsell: 1
    }
  },
  created () {
  },
  methods: {

  }
}
</script>
<style lang="scss" module>
</style>
